import { storeConfigVar } from '@root/core/services/graphql/cache';

const pwaConfig = storeConfigVar();

export const PRIMARY = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.primary_color ? pwaConfig.pwa.primary_color : '#374151';
export const SECONDARY = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.secondary_color ? pwaConfig.pwa.secondary_color : '#818181';

export const FONT_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.font_color ? pwaConfig.pwa.font_color : '#374151';
export const BACKGROUND_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.background_color ? pwaConfig.pwa.background_color : '#FFFFFF';

export const BADGE_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.button_background_color ? pwaConfig.pwa.button_background_color : '#000000';

export const GRAY_PRIMARY = '#999999';
export const GRAY_SECONDARY = '#666666';
export const GRAY_THIRD = '#6E6E6E';
export const GRAY_LIGHT = '#F1F1F1';
export const GRAY_BRD_BTN = '#DFDFDF';
export const GRAY_PRICE = '#AFAFAF';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';
export const BLUE_PRIMARY = '#3695FD';
export const BLUE_SECONDARY = '#ECFCFF';

export const ERROR_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.error_color ? pwaConfig.pwa.error_color : '#FF0000';
export const WARNING_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.warning_msg_color ? pwaConfig.pwa.warning_msg_color : '#6f4400';
export const SUCCESS_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.success_msg_color ? pwaConfig.pwa.success_msg_color : '#46954D';
export const RED = '#FF0000';
export const ORANGE = '#FE5D26';
export const GREEN = '#46954D';
export const SECONDARY_GREEN = '#669932';
export const BLACK = '#000000';
export const WARNING_BACKGROUND = '#FDF0D5';
export const WARNING_LOGO = '#F7C317';
